import React, { useEffect, useState } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  List,
  MenuItem,
  Select,
} from "@mui/material";
import {
  UpdateCurrentBalance,
  singleUserApi,
  userbalance,
} from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import { Form, Formik, Field } from "formik";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const validationSchema = yup.object({
  type: yup.mixed().required("Balance Type is required"),
  amount: yup.number().required("Amount is required"),
  admin_transaction_password: yup
    .string()
    .required("Transaction Password is required"),
});

const Updateuserbalance = () => {
  const { t } = useTranslation();
  const [singleUser, setSingleUser] = useState({});
  const [userBalance, setUserBalance] = useState([]);
  const [allBalance, setAllBalance] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [updateRecord, setUpdateRecord] = useState(false);
  const location = useLocation();
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [currentBalance, setCurrentBalance] = useState("");

  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const user_id = location.state?.id || "";
  const getSingleUserFunction = () => {
    let params = {
      userid: user_id,
    };
    setIsLoading(true);
    singleUserApi(params, (response) => {
      setSingleUser(response?.data?.data[0]);
      setIsLoading(false);
    });
  };

  const handleSubmit = (data, setSubmitting, resetForm) => {
    data.userid = user_id;
    data.currency = selectedCurrency;
    data.currentBalanceEUR = allBalance["eur"];
    data.currentBalanceUSD = allBalance["usd"];

    if (data.currency === "") {
      setalertData({
        show: true,
        message: "Please select currency.",
        variant: "error",
      });
      setSubmitting(false);
    } else if (data.amount <= 0) {
      setalertData({
        show: true,
        message: "Invalid amount.",
        variant: "error",
      });
      setSubmitting(false);
    } else {
      UpdateCurrentBalance(
        data,
        (response) => {
          if (response?.data?.status === "error") {
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: "error",
            });
            setSubmitting(false);
          } else if (response?.data?.status === "success") {
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: "success",
            });
            setSelectedCurrency("");
            setCurrentBalance("");
            resetForm();
            setUpdateRecord(true);
            setSubmitting(false);
          } else {
            setalertData({
              show: true,
              message: "Something went wrong. Please try again later",
              variant: "error",
            });
          }
        },
        (error) => {}
      );
    }
  };

  useEffect(() => {
    getSingleUserFunction();
  }, [updateRecord]);

  const handelSelectChange = (event) => {
    setSelectedCurrency(event.target.value);

    let params = {
      userid: user_id,
    };
    setIsLoading1(true);
    userbalance(params, (response) => {
      setAllBalance(response?.data?.data);
      if (event.target.value === "EUR") {
        setCurrentBalance(response?.data?.data?.eur);
      } else {
        setCurrentBalance(response?.data?.data?.usd);
      }
      setIsLoading1(false);
    });
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={6} xs={12}>
        <JumboCardQuick title={t("pages.title.userbalance")} noWrapper>
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {isLoading || isLoading1 ? (
            <Div
              sx={{
                mt: "20%",
                ml: "45%",
                mb: "20%",
              }}
            >
              <CircularProgress />
            </Div>
          ) : (
            <List disablePadding sx={{ mb: 2 }}>
              <Formik
                validateOnChange={true}
                initialValues={{
                  username: singleUser?.username,
                  current_balance: "",
                  type: "",
                  amount: "",
                  admin_transaction_password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  handleSubmit(data, setSubmitting, resetForm);
                }}
              >
                {({ isSubmitting, setFieldValue }) => (
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    autoComplete="off"
                  >
                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="username"
                        label="User Name"
                        type="text"
                        InputProps={{
                          readOnly: true,
                        }}
                      ></JumboTextField>
                    </Div>
                    <Box sx={{ mt: 3, mb: 3, pl: 2, pr: 2 }}>
                      <FormControl sx={{ minWidth: "100%" }}>
                        <InputLabel id="demo-select-small">
                          Select Currency
                        </InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={selectedCurrency}
                          label="Select Currency"
                          name="currency"
                          onChange={(e) => {
                            setFieldValue("currency", e.target.value);
                            handelSelectChange(e);
                          }}
                        >
                          <MenuItem value={"EUR"}>EUR</MenuItem>
                          <MenuItem value={"USD"}>USD</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Div sx={{ mt: 3, mb: 3, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="current_balance"
                        label="Current Balance"
                        type="number"
                        InputProps={{
                          value: currentBalance,
                          readOnly: true,
                        }}
                      />
                    </Div>

                    <Box sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <Field name="type" as={Select} fullWidth displayEmpty>
                        <MenuItem value="">
                          <em>Balance Type</em>
                        </MenuItem>
                        <MenuItem value="Add">{t("pages.title.add")}</MenuItem>
                        <MenuItem value="Deduct">
                          {t("pages.title.deduct")}
                        </MenuItem>
                      </Field>
                    </Box>

                    <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="amount"
                        label="Amount"
                        type="number"
                      />
                    </Div>
                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <JumboTextField
                        fullWidth
                        name="admin_transaction_password"
                        label="Transaction Password"
                        type="password"
                      />
                    </Div>

                    <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        {t("pages.title.submit")}
                      </LoadingButton>
                    </Div>
                  </Form>
                )}
              </Formik>
            </List>
          )}
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default Updateuserbalance;
